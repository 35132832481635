import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "home/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wagon: file(relativePath: { eq: "home/wagon.png" }) {
        childImageSharp {
          fluid(maxWidth: 392, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      donkey: file(relativePath: { eq: "home/donkey.jpg" }) {
        childImageSharp {
          fixed(height: 325) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      history: file(relativePath: { eq: "home/history.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      transition: file(relativePath: { eq: "home/transition.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      objectives: file(relativePath: { eq: "home/objectives.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      feed: file(relativePath: { eq: "home/feed.jpg" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      divider: file(relativePath: { eq: "home/divider_br.png" }) {
        childImageSharp {
          fixed(width: 275) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
  <Layout>
    <SEO title="Home" />
    <br />
    <div className="row">
      <div className="col-12 col-lg-9">
        <div className="text-center">
          <Img alt="Baxter Barn logo" fluid={data.logo.childImageSharp.fluid} />
          <div className="row mt-4">
            <div className="col">
              <Img alt="Donkey at Baxter Barn" fixed={data.donkey.childImageSharp.fixed} />
            </div>
            <div className="col" style={{margin: `auto`}}>
              <Img alt="flower divider" fixed={data.divider.childImageSharp.fixed} />
              <p>
                <strong>Fall City History Since 1875</strong>
              </p>
              <div class="mb-2">
                <span>The mission of Baxter Barn is to demonstrate sustainability and conservation on a historical farm where domesticated plants and animals are as important as native plants and wildlife.</span>
              </div>
              <Img alt="flower divider" fixed={data.divider.childImageSharp.fixed} />
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <div style={{maxWidth: 392, margin: `auto`}}>
            <Img alt="Wagon at Baxter Barn" fluid={data.wagon.childImageSharp.fluid} />
          </div>
          <div className="mt-4">
            <small>We are passionate about our birds and because of that our philosophy is to make sure our birds have the proper care from our farm to your home.
            Proper care leads to less stress and healthier birds reducing the chance of loss. From supplies to feeders, water, bedding, brooder, heat, pro-biotic, grit and feed; to birds of the same age, same location, how they are transported including how long, temperature in the car and in the box are all important to maintain the health of the bird.
            We simply want you to have a good experience which in turn gives us joy and satisfied clients.</small>
            <div className="mt-3">
              <small><a href="http://youtu.be/fWO94QYIT_M" target="_blank" rel="noopener noreferrer">Click here</a> to watch the Baxter Barn Video!</small>
              <br/>
              <small><a href="http://youtu.be/yRK9wL-eDng" target="_blank" rel="noopener noreferrer">Click here</a> to watch the Salmon-Safe Television Commercial!</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <a href="/history"><Img fixed={data.history.childImageSharp.fixed} style={{margin: `auto`}} className={styles.sidebarImg}/></a>
        <a href="/transition"><Img fixed={data.transition.childImageSharp.fixed} style={{margin: `auto`}} className={styles.sidebarImg}/></a>
        <a href="/objectives"><Img fixed={data.objectives.childImageSharp.fixed} style={{margin: `auto`}} className={styles.sidebarImg}/></a>
        <a href="/farm-business/feed-farm-supplies"><Img fixed={data.feed.childImageSharp.fixed} style={{margin: `auto`}} className={styles.sidebarImg}/></a>
      </div>
    </div>
  </Layout>
)}

export default IndexPage
